<template>
  <div id="app">
    <ControlPanel v-if="isAdmin" />

    <transition name="fade" mode="out-in">
      <router-view />
    </transition>

    <div
      class="floating-buttons-module"
      v-if="floatingButtonsModule && floatingButtonsModule.enabled"
    >
      <Component
        v-for="(button, idx) in floatingButtonsModule.items"
        v-bind:key="idx"
        v-bind:is="getFloatingButton(button.id)"
        v-bind:props="button.props"
      />
    </div>
  </div>
</template>

<script>
const ControlPanel = () => import("@/components/core/ControlPanel");
import FloatingButtonsModule from "@/utils/floating-buttons";
export default {
  metaInfo: {
    titleTemplate: `%s | ${process.env.VUE_APP_BRAND}`,
  },
  components: {
    ControlPanel,
  },
  computed: {
    isAdmin() {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.role == "ROOT";
      }
    },
    floatingButtonsModule() {
      return this.$store.state.system?.configs?.THEME?.floatingButtonsModule;
    },
  },
  methods: {
    getFloatingButton(id) {
      return FloatingButtonsModule.get(id);
    },
  },
  created() {
    this.$store.dispatch("plans/GET_PLANS");
  },
};
</script>

<style lang="scss">
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}
</style>