<template>
  <div class="smart-label">
    <template v-if="isEditing">
      <textarea v-bind:id="id"
        type="text"
        style="margin-bottom: 0!important;"
        v-model="text"
        v-on:keyup.enter="save"
        v-on:keyup.esc="cancel"
        v-bind:class="className">
      </textarea>
      <div class="text-right mb-3">
        <a href="javscript:;"
          class="btn btn-link btn-sm"
          v-on:click.prevent="cancel">
          Cancel
        </a>
        <button class="btn btn-primary btn-sm"
          v-on:click="save">
          Update
        </button>
      </div>
    </template>
    <Label v-else v-bind:tag="tag" v-bind:text="text" v-bind:class="className" v-on:dblclick="onDblClick"/>
  </div>
</template>

<script>
  import { firestore, } from "@/services/firebase.service";
  import Label from "@/components/core/Label.js";
  import $ from "jquery";
  export default {
    components: {
      Label,
    },
    props: {
      className: String,
      tag: String,
      value: String,
      target: {
        required: true,
        type: String,
      },
    },
    data() {
      return {
        isEditing: false,
        initialValue: this.value,
      };
    },
    computed: {
      text: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value.trim());
        }
      },
      id() {
        return "input-" + this.target;
      },
    },
    methods: {
      onDblClick() {
        this.isEditing = true;
        this.$nextTick(() => $("#" + this.id).focus());
      },
      cancel() {
        this.isEditing = false;
        this.text = this.initialValue;
      },
      async save() {
        const update = {
          content: {
            [this.$parent.$options.name]: {
              [this.target]: this.text,
            },
          },
        };

        this.isEditing = false;

        try {
          await firestore.doc("system/THEME").set(update, { merge: true, });
        } catch(e) {
          console.error(e);
          this.isEditing = true;
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  textarea {
    background: transparent;
    border: none;
    line-height: 1.15;
    resize: none;
    width: 100%;
  }
</style>