import { firestore, } from "@/services/firebase.service";

export default {
  namespaced: true,
  state: {
    configs: {},
  },
  getters: {
    enabledPaymentProviders: state => state.configs.PAYMENT.providers.filter(provider => provider.enabled),
    pages                  : state => state.configs.THEME.pages,
    header                 : state => state.configs.THEME.header || "HeaderOne",
    menu                   : state => state.configs.THEME.menu,
    content                : state => state.configs.THEME.content,
    logo                   : state => state.configs.THEME.logo,
  },
  mutations: {
    ADD_CONFIG: (state, payload) => state.configs              = { ...state.configs, ...payload },
    SET_HEADER: (state, payload) => state.configs.THEME.header = payload,
    SET_LOGO  : (state, payload) => state.configs.THEME.logo   = { ...state.configs.THEME.logo, ...payload, },
  },
  actions: {
    GET_CONFIGS: ({ commit, }) => new Promise((resolve, reject) => {
      firestore.collection("system")
        .get()
        .then(snapshot => {
          for (const doc of snapshot.docs) {
            commit("ADD_CONFIG", { [doc.id]: doc.data(), });
          }
          resolve();
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    }),
  },
};